import * as React from "react";
import * as styles from "./oil.module.css";

import PageLink from "../pageLink";

import Drop from "../../images/svg/Drop.inline.svg";

const oilHref = "/dobierz-olej";

const OilTool = () => {
  return (
    <PageLink to={oilHref} className={styles.oil}>
      <span className={styles.oil__icon}>
        <Drop />
      </span>
      <p className={styles.oil__label}>Narzędzie doboru oleju</p>
    </PageLink>
  );
};

export default OilTool;
